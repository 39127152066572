import React       from 'react';
import { graphql } from 'gatsby';
// import Img         from 'gatsby-image';

import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
// import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import Display           from '@interness/theme-default/src/components/Display/Display';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import HeaderImage       from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';


function IndexPage(props) {
  return (
    <>
      {/*<Carousel>*/}
      {/*  {props.data.headerImages.edges.map((image) => (*/}
      {/*    <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>*/}
      {/*  ))}*/}
      {/*</Carousel>*/}
      <HeaderImage>
        <RandomBrandHeaderImages count={3} />
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Krevet</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Juwelier Krevet, im Süden der Landeshauptstadt Düsseldorf gelegen, ist seit 1964 ein Begriff für außergewöhnlichen Schmuck, feine Uhren, exklusive Trauringe und Eheringe in Platin, Gold und Silber.</p>
            <p>  In unseren moderneren Geschäftsräumen, in der Bonner Str. 7 in Düsseldorf Holthausen, möchten wir Ihnen ein noch schöneres Einkaufserlebnis ermöglichen.</p>
            <p>Wir laden Sie herzlich ein, bei Ihrem Besuch in unserem Geschäft Ihre Lieblingsstücke auszuwählen, anzuprobieren und zu begutachten.
            In unserem fein ausgewählten Sortiment finden Sie sowohl erlesenen als auch top aktuellen Schmuck, moderne und klassische Uhren, traumhafte Trauringe und Eheringe sowie extravagante Accessoires.</p>
            <p>Des weiteren können Sie Uhrenlederbänder der Firma Kaufmann bei uns beziehen.
            Ein Besteck-Corner der Firma Robbe & Berking rundet unser Sortiment ab. Gerne stellen wir Ihnen auch individuelle Geschenkgutscheine aus.</p>
            <p>Freundliche und fachlich kompetente Beratung, sowie ein erstklassiger Service sind für uns eine Selbstverständlichkeit.</p>
            <p>Bis bald in Düsseldorf.<br/>Ihr Team von Juwelier Krevet.</p>
          </span>
        </section>
        <LiveAnnouncements/>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unser Markenangebot</Heading>
          <h3 style={{ textAlign: 'center' }}>Unsere größte Stärke ist unsere Schwäche für schönen Schmuck</h3>
          <Display />
          <Spacer/>
          <div style={{ textAlign: 'center' }}>
            <CallToAction/>
          </div>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    headerImages: allFile(filter: {relativeDirectory: {eq: "headers/index"}}) {
      edges {
        node {
          ...HeaderImages
        }
      }
    }
  }
`;
